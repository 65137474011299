var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"pcontent"},[_c('vue-picture-swipe',{attrs:{"options":{shareEl: false},"items":[

  {src: require('@/assets/gallery/dec14_01.jpeg'),thumbnail: require('@/assets/gallery/dec14_01_t.jpeg'), w: 768,h: 1024},
  {src: require('@/assets/gallery/dec14_02.jpeg'),thumbnail: require('@/assets/gallery/dec14_02_t.jpeg'), w: 768,h: 1024},
  {src: require('@/assets/gallery/dec14_03.jpeg'),thumbnail: require('@/assets/gallery/dec14_03_t.jpeg'), w: 768,h: 1024},
  {src: require('@/assets/gallery/dec14_04.jpeg'),thumbnail: require('@/assets/gallery/dec14_04_t.jpeg'), w: 768,h: 1024},
  {src: require('@/assets/gallery/dec14_05.jpeg'),thumbnail: require('@/assets/gallery/dec14_05_t.jpeg'), w: 1024,h: 768},
  {src: require('@/assets/gallery/dec14_06.jpeg'),thumbnail: require('@/assets/gallery/dec14_06_t.jpeg'), w: 768,h: 1024},

  {src: require('@/assets/gallery/dec4_01.jpeg'),thumbnail: require('@/assets/gallery/dec4_01_t.jpeg'), w: 1024,h: 768},
  {src: require('@/assets/gallery/dec4_02.jpeg'),thumbnail: require('@/assets/gallery/dec4_02_t.jpeg'), w: 768,h: 1024},
  {src: require('@/assets/gallery/dec4_03.jpeg'),thumbnail: require('@/assets/gallery/dec4_03_t.jpeg'), w: 768,h: 1024},
  {src: require('@/assets/gallery/dec4_04.jpeg'),thumbnail: require('@/assets/gallery/dec4_04_t.jpeg'), w: 768,h: 1024},
  {src: require('@/assets/gallery/dec4_05.jpeg'),thumbnail: require('@/assets/gallery/dec4_05_t.jpeg'), w: 768,h: 1024},
  {src: require('@/assets/gallery/dec4_06.jpeg'),thumbnail: require('@/assets/gallery/dec4_06_t.jpeg'), w: 768,h: 1024},

  {src: require('@/assets/gallery/22jul1.jpeg'),thumbnail: require('@/assets/gallery/22jul1_t.jpeg'), w: 1024,h: 768},
  {src: require('@/assets/gallery/22jul2.jpeg'),thumbnail: require('@/assets/gallery/22jul2_t.jpeg'), w: 768,h: 1024},
  {src: require('@/assets/gallery/22jul3.jpeg'),thumbnail: require('@/assets/gallery/22jul3_t.jpeg'), w: 814,h: 1024},
  {src: require('@/assets/gallery/22jul4.jpeg'),thumbnail: require('@/assets/gallery/22jul4_t.jpeg'), w: 693,h: 1024},
  {src: require('@/assets/gallery/22jul5.jpeg'),thumbnail: require('@/assets/gallery/22jul5_t.jpeg'), w: 768,h: 1024},
  {src: require('@/assets/gallery/22jul6.jpeg'),thumbnail: require('@/assets/gallery/22jul6_t.jpeg'), w: 1024,h: 965},
  {src: require('@/assets/gallery/22jul7.jpeg'),thumbnail: require('@/assets/gallery/22jul7_t.jpeg'), w: 768,h: 1024},

    {src: require('@/assets/gallery/1.jpeg'),thumbnail: require('@/assets/gallery/1_t.jpeg'), w: 1024,h: 768},
    {src: require('@/assets/gallery/2.jpeg'),thumbnail: require('@/assets/gallery/2_t.jpeg'), w: 768,h: 1024},
    {src: require('@/assets/gallery/3.jpeg'),thumbnail: require('@/assets/gallery/3_t.jpeg'), w: 768,h: 1024},
    {src: require('@/assets/gallery/4.jpeg'),thumbnail: require('@/assets/gallery/4_t.jpeg'), w: 768,h: 1024},
    {src: require('@/assets/gallery/5.jpeg'),thumbnail: require('@/assets/gallery/5_t.jpeg'), w: 768,h: 1024},
    {src: require('@/assets/gallery/6.jpeg'),thumbnail: require('@/assets/gallery/6_t.jpeg'), w: 768,h: 1024},

    {src: require('@/assets/gallery/_54A6671.jpg'),thumbnail: require('@/assets/gallery/_54A6671_t.jpg'), w: 3543,h: 2362},
    {src: require('@/assets/gallery/_54A6675.jpg'),thumbnail: require('@/assets/gallery/_54A6675_t.jpg'), w: 3543,h: 2362},
    {src: require('@/assets/gallery/_54A6689.jpg'),thumbnail: require('@/assets/gallery/_54A6689_t.jpg'), w: 3543,h: 2362},
    {src: require('@/assets/gallery/_54A6697.jpg'),thumbnail: require('@/assets/gallery/_54A6697_t.jpg'), w: 3543,h: 2362},
    {src: require('@/assets/gallery/_54A6713.jpg'),thumbnail: require('@/assets/gallery/_54A6713_t.jpg'), w: 3543,h: 2362},
    {src: require('@/assets/gallery/_54A6715.jpg'),thumbnail: require('@/assets/gallery/_54A6715_t.jpg'), w: 3543,h: 2362},
    {src: require('@/assets/gallery/_54A6720.jpg'),thumbnail: require('@/assets/gallery/_54A6720_t.jpg'), w: 3543,h: 2362},
    {src: require('@/assets/gallery/_54A6736.jpg'),thumbnail: require('@/assets/gallery/_54A6736_t.jpg'), w: 3543,h: 2740},
    {src: require('@/assets/gallery/_54A6741.jpg'),thumbnail: require('@/assets/gallery/_54A6741_t.jpg'), w: 3543,h: 2362},
    {src: require('@/assets/gallery/_54A6743.jpg'),thumbnail: require('@/assets/gallery/_54A6743_t.jpg'), w: 3543,h: 2362},
    {src: require('@/assets/gallery/_54A6745.jpg'),thumbnail: require('@/assets/gallery/_54A6745_t.jpg'), w: 3543,h: 2362},
    {src: require('@/assets/gallery/_54A6748.jpg'),thumbnail: require('@/assets/gallery/_54A6748_t.jpg'), w: 3543,h: 2362},
    {src: require('@/assets/gallery/_54A6753.jpg'),thumbnail: require('@/assets/gallery/_54A6753_t.jpg'), w: 3543,h: 2362},
    {src: require('@/assets/gallery/_54A6757.jpg'),thumbnail: require('@/assets/gallery/_54A6757_t.jpg'), w: 3543,h: 2362},
    {src: require('@/assets/gallery/_54A6762.jpg'),thumbnail: require('@/assets/gallery/_54A6762_t.jpg'), w: 3543,h: 2362},
    {src: require('@/assets/gallery/_54A6767.jpg'),thumbnail: require('@/assets/gallery/_54A6767_t.jpg'), w: 3543,h: 2362},
    {src: require('@/assets/gallery/_54A6775.jpg'),thumbnail: require('@/assets/gallery/_54A6775_t.jpg'), w: 3543,h: 2362},
    {src: require('@/assets/gallery/_54A6777.jpg'),thumbnail: require('@/assets/gallery/_54A6777_t.jpg'), w: 3543,h: 2362},
    {src: require('@/assets/gallery/_54A6780.jpg'),thumbnail: require('@/assets/gallery/_54A6780_t.jpg'), w: 3543,h: 2362},
    {src: require('@/assets/gallery/_54A6791.jpg'),thumbnail: require('@/assets/gallery/_54A6791_t.jpg'), w: 3543,h: 2362},
    {src: require('@/assets/gallery/_54A2212.jpg'),thumbnail: require('@/assets/gallery/_54A2212_t.jpg'), w: 3543,h: 2362},
    {src: require('@/assets/gallery/_54A2230.jpg'),thumbnail: require('@/assets/gallery/_54A2230_t.jpg'), w: 3543,h: 2362},
    {src: require('@/assets/gallery/_54A2239.jpg'),thumbnail: require('@/assets/gallery/_54A2239_t.jpg'), w: 3543,h: 2362},
    {src: require('@/assets/gallery/_54A2242.jpg'),thumbnail: require('@/assets/gallery/_54A2242_t.jpg'), w: 3543,h: 2362},
    {src: require('@/assets/gallery/_54A2245.jpg'),thumbnail: require('@/assets/gallery/_54A2245_t.jpg'), w: 3543,h: 2362},
    {src: require('@/assets/gallery/_54A2259.jpg'),thumbnail: require('@/assets/gallery/_54A2259_t.jpg'), w: 3543,h: 2362},
    {src: require('@/assets/gallery/_54A2271.jpg'),thumbnail: require('@/assets/gallery/_54A2271_t.jpg'), w: 3543,h: 2362} ]}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }