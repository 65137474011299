<template>
    <div class="contentOuter">
        <div class="banner">
            <div class="bannercontent">
                <div class="bannertitle">Workshops</div>
            </div>
        </div>
        <div class="content">
        <br>

        <div class="detailBoxHead"><b>Workshop</b> stippen op keramiek</div> <br>
        <div class="pcontent">
              Creëer je eigen unieke stukje servies. Dit staat centraal bij de workshop 'stippen op keramiek'. 
            Met behulp van porseleinverf breng je in je favoriete kleuren een stip patroon aan op bijvoorbeeld een bordje of schaaltje. 
            Uiteraard wordt er precies uitgelegd hoe je dat het beste kan doen. Dit kan echt van alles zijn; van een vast patroon tot 
            zelf bedachte figuren. Als je helemaal tevreden bent over het eindresultaat bak je het thuis af in een gewone oven. 
            Daarna is het klaar voor gebruik.
        </div> <br>
        <div class="detailBox">
            <div class="detailBoxItem"><div class="detailBoxGrey"><ClockTimeTwoOutline/> </div> ± 2 uur </div>
            <div class="detailBoxItem"><div class="detailBoxGrey"><CashMultiple/> </div> &euro;20,- per persoon (minimaal 5 personen) </div>
            <div class="detailBoxItem"><div class="detailBoxTitle">Hierbij zit inbegrepen</div>
            <br> &emsp;- 2 stuks keramiek
            <br> &emsp;- Gebruik van porseleinverf
            <br> &emsp;- Gebruik van materialen
            <br> &emsp;- Instructiekaart om je keramiek thuis af te bakken
            <br> &emsp;- Koffie / Thee met iets lekkers
 </div>
        <div class="img">
         <vue-picture-swipe :options="{shareEl: false}" :items="[
            {src: require('@/assets/workshops/_54A2242.jpg'),thumbnail: require('@/assets/workshops/_54A2242_t.jpg'), w: 3543,h: 2362},
            {src: require('@/assets/workshops/_54A2254.jpg'),thumbnail: require('@/assets/workshops/_54A2254_t.jpg'), w: 3543,h: 2362},
            {src: require('@/assets/workshops/_54A2349.jpg'),thumbnail: require('@/assets/workshops/_54A2349_t.jpg'), w: 3543,h: 2362},
            {src: require('@/assets/workshops/shop.jpg'),thumbnail: require('@/assets/workshops/shop_t.jpg'), w: 948,h: 1273},
            ]">
        </vue-picture-swipe>
        </div>
        </div>
        
    </div>
    </div>
</template>

<script>
import ClockTimeTwoOutline from 'vue-material-design-icons/ClockTimeTwoOutline.vue'
import CashMultiple from 'vue-material-design-icons/CashMultiple.vue'
import VuePictureSwipe from 'vue-picture-swipe';
export default {
  name: 'Workshops',
  components: {
      ClockTimeTwoOutline,
      CashMultiple,
      VuePictureSwipe
  }
}
</script>

<style scoped>
    .img{
        text-align: left;
        padding-top: 12px;
        padding-left: 8px;
        padding-bottom: 10px;
    }
    
     .pagetitle{
        font-size: 25px;
        float: left;
    }

    .pcontent{
        max-width: 1024px;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        display: inline-block;
        text-align: left;
    }

    .banner{
        background-image: url(~@/assets/workshops/workshops.jpg);
        background-position: center;
        background-color: #d9d9d9;
        background-size: 100%;
        background-repeat: no-repeat;
        width: 100%;
        height: 150px;
        display: flex;
        justify-content: center;
    }
    .bannercontent{
        max-width: 1024px;
        width: 100%;
        align-self: center;

    }
    .bannertitle{
        color: #ffffff
    }
   
</style>
