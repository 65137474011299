<template>
    <div class="contentOuter">
            <div class="banner">
            <div class="bannercontent">
                <div class="bannertitle">Kinderfeestjes</div>
            </div>
        </div>
    <div class="content">
        <div class="pcontent">
             <div class="detailBoxHead"><b>Vier je creatieve kinderfeestje bij de Stipbox.
            <br>Dat is pas feest!</b></div>
            Voordat je samen met je vriendjes en vriendinnetjes de leukste dingen gaat maken, wordt je als jarige eerst in het zonnetje gezet. De rode loper wordt voor je uitgelegd en je mag plaatsnemen op de grote verjaardagsstoel. Als we allemaal moe zijn van het zingen voor  jou is het tijd voor limonade en wat lekkers. Daarna … knutselen maar!
            <br>Oh en niet vergeten, voordat je naar huis gaat, allemaal grabbelen in de grote grabbelton. <br>
        </div> <br>

        <div class="detailBoxHead"><b>Kinderfeestje</b> Letter op mok</div> <br>
         <div class="pcontent">
             Plak een letter op de mok zodat je eromheen prachtig kunt versieren. Dat mag de eerste letter van je naam zijn of de letter 'M' van mama, alles mag!
             Ben je helemaal klaar dan halen we de plakletter er weer af. Thuis afbakken in de oven en gebruiken maar! 
        </div> <br>
        <div class="detailBox">
            <div class="detailBoxItem"><div class="detailBoxGrey"><ClockTimeTwoOutline/></div> ± 1.5 uur </div>
            <div class="detailBoxItem"><div class="detailBoxGrey"><CashMultiple/> </div> &euro;9,75,- per kind (minimaal 5 kinderen) </div>
            <div class="detailBoxItem"><div class="detailBoxGrey"><FormatListBulleted/> </div> <div class="detailBoxTitle">Hierbij zit inbegrepen</div>
            <br> &emsp;&emsp;+ Mok
            <br> &emsp;&emsp;+ Gebruik van porseleinstiften en materialen
            <br> &emsp;&emsp;+ Instructiekaart om je mok thuis af te bakken
            <br> &emsp;&emsp;+ Limonade met iets lekkers
            <br> &emsp;&emsp;+ Grabbelen
        </div> <br>
        <div class="img">
         <vue-picture-swipe :options="{shareEl: false}" :items="[
            {src: require('@/assets/kinderfeestjes/_54A2305.jpg'),thumbnail: require('@/assets/kinderfeestjes/_54A2305_t.jpg'), w: 3543,h: 2362},
            {src: require('@/assets/gallery/9.jpeg'),thumbnail: require('@/assets/gallery/9_t.jpeg'), w: 768,h: 1024},
            ]">
        </vue-picture-swipe>
        </div>
        </div>
        <br><br>
        <div class="detailBoxHead"><b>Kinderfeestje</b> Tekening op bord</div> <br>
        <div class="pcontent">
            Kies een mooie tekening uit en breng het met behulp van carbonpapier over op het bord! Inkleuren met porseleinstiften en als het droog is afbakken in de oven.
            Een eigen tekening maken mag ook natuurlijk.
        </div> <br>
        <div class="detailBox"> 
            <div class="detailBoxItem"><div class="detailBoxGrey"><ClockTimeTwoOutline/> </div> ± 1.5 uur </div>
            <div class="detailBoxItem"><div class="detailBoxGrey"><CashMultiple/> </div> &euro;9,75,- per kind (minimaal 5 kinderen) </div>
            <div class="detailBoxItem"><div class="detailBoxGrey"><FormatListBulleted/> </div> <div class="detailBoxTitle">Hierbij zit inbegrepen</div>
            <br> &emsp;&emsp;+ Bord
            <br> &emsp;&emsp;+ Gebruik van porseleinstiften en materialen
            <br> &emsp;&emsp;+ Instructiekaart om je bord thuis af te bakken
            <br> &emsp;&emsp;+ Limonade met iets lekkers
            <br> &emsp;&emsp;+ Grabbelen
        </div> 
        <div class="img">
         <vue-picture-swipe :options="{shareEl: false}" :items="[
            {src: require('@/assets/kinderfeestjes/_54A2291.jpg'),thumbnail: require('@/assets/kinderfeestjes/_54A2291_t.jpg'), w: 3543,h: 2362},
            {src: require('@/assets/kinderfeestjes/_54A2297.jpg'),thumbnail: require('@/assets/kinderfeestjes/_54A2297_t.jpg'), w: 3543,h: 2362},
            {src: require('@/assets/gallery/7.jpeg'),thumbnail: require('@/assets/gallery/7_t.jpeg'), w: 768,h: 1024},
            {src: require('@/assets/gallery/8.jpeg'),thumbnail: require('@/assets/gallery/8_t.jpeg'), w: 768,h: 1024},
            ]">
        </vue-picture-swipe>
        </div>
        </div> <br>
        <br><br>
        <div class="detailBoxHead"><b>Kinderfeestje</b> Ontwerp je eigen schort</div> <br>
        <div class="pcontent">
            Bak nu je koekjes of help mee in de keuken met een schort waar je eigen ontwerp op staat
        </div> <br>
        <div class="detailBox">
            <div class="detailBoxItem"><div class="detailBoxGrey"><ClockTimeTwoOutline/> </div> ± 1.5 uur </div>
            <div class="detailBoxItem"><div class="detailBoxGrey"><CashMultiple/> </div> &euro;10,75,- per kind (minimaal 5 kinderen) </div>
            <div class="detailBoxItem"><div class="detailBoxGrey"><FormatListBulleted/> </div> <div class="detailBoxTitle">Hierbij zit inbegrepen</div>
            <br> &emsp;&emsp;+ Schort
            <br> &emsp;&emsp;+ Gebruik van textielstiften en materialen 
            <br> &emsp;&emsp;+ Limonade met iets lekkers
            <br> &emsp;&emsp;+ Grabbelen
        </div>
        <div class="img">
         <vue-picture-swipe :options="{shareEl: false}" :items="[
            {src: require('@/assets/kinderfeestjes/_54A2290.jpg'),thumbnail: require('@/assets/kinderfeestjes/_54A2290_t.jpg'), w: 3543,h: 2362},
            ]">
        </vue-picture-swipe>
        </div>
        </div> 
    </div>
    </div>
</template>

<script>
import ClockTimeTwoOutline from 'vue-material-design-icons/ClockTimeTwoOutline.vue'
import CashMultiple from 'vue-material-design-icons/CashMultiple.vue'
import FormatListBulleted from 'vue-material-design-icons/FormatListBulleted.vue'
import VuePictureSwipe from 'vue-picture-swipe';
export default {
  name: 'Kinderfeestjes',
  components: {
    ClockTimeTwoOutline,
      CashMultiple,
      FormatListBulleted,
      VuePictureSwipe
  }
}
</script>

<style scoped>
    .img{
        text-align: left;
        padding-top: 12px;
        padding-left: 8px;
        padding-bottom: 10px;
    }   
    .pagetitle{
        font-size: 25px;
        float: left;
    }

    .pcontent{
        max-width: 1024px;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        display: inline-block;
        text-align: left;
    }

    .banner{
        background-image: url(~@/assets/kinderfeestjes/kinderfeestjes.jpg);
        background-position: center;
        background-color: #d9d9d9;
        background-size: 100%;
        background-repeat: no-repeat;
        width: 100%;
        height: 150px;
        display: flex;
        justify-content: center;
    }
    .bannercontent{
        max-width: 1024px;
        width: 100%;
        align-self: center;

    }
    .bannertitle{
        color: rgb(255, 255, 255);
    }
   
</style>
