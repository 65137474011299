<template>
    <div class="topBar">
        <div class="topBarContent">
            De Stip Box <chevron-right-icon/> <b>{{$route.name}}</b>
        </div>
    </div>
</template>

<script>
    import ChevronRightIcon from 'vue-material-design-icons/ChevronRight.vue'
    export default {
        components: {
            ChevronRightIcon
        },
        name: "PageTitle"
    }
</script>

<style scoped>
    .topBar{
        background-color: #c4343b;
        border: 0px;
        border-bottom: 2px;
        border-color: #a32c32;
        border-style: solid;
        text-align: center;
        width: 100%;
        height: 40px;
        top: 0;
        z-index: 100;
        
    }
    .topBarContent{
        text-align: left;
        display: inline-block;
        width: 1024px;
        height: 70px;
        color: white;
        line-height: 42px;
        left: 15px;
        position: relative;
    }
    @media only screen and (max-width: 1024px){
        .topBarContent{
            width: fit-content;
        }
        .topBar{
            text-align: left;
        }
    }
</style>