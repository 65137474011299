var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contentOuter"},[_vm._m(0),_c('div',{staticClass:"content"},[_vm._m(1),_vm._v(" "),_c('br'),_vm._m(2),_vm._v(" "),_c('br'),_c('div',{staticClass:"pcontent"},[_vm._v(" Plak een letter op de mok zodat je eromheen prachtig kunt versieren. Dat mag de eerste letter van je naam zijn of de letter 'M' van mama, alles mag! Ben je helemaal klaar dan halen we de plakletter er weer af. Thuis afbakken in de oven en gebruiken maar! ")]),_vm._v(" "),_c('br'),_c('div',{staticClass:"detailBox"},[_c('div',{staticClass:"detailBoxItem"},[_c('div',{staticClass:"detailBoxGrey"},[_c('ClockTimeTwoOutline')],1),_vm._v(" ± 1.5 uur ")]),_c('div',{staticClass:"detailBoxItem"},[_c('div',{staticClass:"detailBoxGrey"},[_c('CashMultiple')],1),_vm._v(" €9,75,- per kind (minimaal 5 kinderen) ")]),_c('div',{staticClass:"detailBoxItem"},[_c('div',{staticClass:"detailBoxGrey"},[_c('FormatListBulleted')],1),_vm._v(" "),_c('div',{staticClass:"detailBoxTitle"},[_vm._v("Hierbij zit inbegrepen")]),_c('br'),_vm._v("   + Mok "),_c('br'),_vm._v("   + Gebruik van porseleinstiften en materialen "),_c('br'),_vm._v("   + Instructiekaart om je mok thuis af te bakken "),_c('br'),_vm._v("   + Limonade met iets lekkers "),_c('br'),_vm._v("   + Grabbelen ")]),_vm._v(" "),_c('br'),_c('div',{staticClass:"img"},[_c('vue-picture-swipe',{attrs:{"options":{shareEl: false},"items":[
        {src: require('@/assets/kinderfeestjes/_54A2305.jpg'),thumbnail: require('@/assets/kinderfeestjes/_54A2305_t.jpg'), w: 3543,h: 2362},
        {src: require('@/assets/gallery/9.jpeg'),thumbnail: require('@/assets/gallery/9_t.jpeg'), w: 768,h: 1024} ]}})],1)]),_c('br'),_c('br'),_vm._m(3),_vm._v(" "),_c('br'),_c('div',{staticClass:"pcontent"},[_vm._v(" Kies een mooie tekening uit en breng het met behulp van carbonpapier over op het bord! Inkleuren met porseleinstiften en als het droog is afbakken in de oven. Een eigen tekening maken mag ook natuurlijk. ")]),_vm._v(" "),_c('br'),_c('div',{staticClass:"detailBox"},[_c('div',{staticClass:"detailBoxItem"},[_c('div',{staticClass:"detailBoxGrey"},[_c('ClockTimeTwoOutline')],1),_vm._v(" ± 1.5 uur ")]),_c('div',{staticClass:"detailBoxItem"},[_c('div',{staticClass:"detailBoxGrey"},[_c('CashMultiple')],1),_vm._v(" €9,75,- per kind (minimaal 5 kinderen) ")]),_c('div',{staticClass:"detailBoxItem"},[_c('div',{staticClass:"detailBoxGrey"},[_c('FormatListBulleted')],1),_vm._v(" "),_c('div',{staticClass:"detailBoxTitle"},[_vm._v("Hierbij zit inbegrepen")]),_c('br'),_vm._v("   + Bord "),_c('br'),_vm._v("   + Gebruik van porseleinstiften en materialen "),_c('br'),_vm._v("   + Instructiekaart om je bord thuis af te bakken "),_c('br'),_vm._v("   + Limonade met iets lekkers "),_c('br'),_vm._v("   + Grabbelen ")]),_c('div',{staticClass:"img"},[_c('vue-picture-swipe',{attrs:{"options":{shareEl: false},"items":[
        {src: require('@/assets/kinderfeestjes/_54A2291.jpg'),thumbnail: require('@/assets/kinderfeestjes/_54A2291_t.jpg'), w: 3543,h: 2362},
        {src: require('@/assets/kinderfeestjes/_54A2297.jpg'),thumbnail: require('@/assets/kinderfeestjes/_54A2297_t.jpg'), w: 3543,h: 2362},
        {src: require('@/assets/gallery/7.jpeg'),thumbnail: require('@/assets/gallery/7_t.jpeg'), w: 768,h: 1024},
        {src: require('@/assets/gallery/8.jpeg'),thumbnail: require('@/assets/gallery/8_t.jpeg'), w: 768,h: 1024} ]}})],1)]),_vm._v(" "),_c('br'),_c('br'),_c('br'),_vm._m(4),_vm._v(" "),_c('br'),_c('div',{staticClass:"pcontent"},[_vm._v(" Bak nu je koekjes of help mee in de keuken met een schort waar je eigen ontwerp op staat ")]),_vm._v(" "),_c('br'),_c('div',{staticClass:"detailBox"},[_c('div',{staticClass:"detailBoxItem"},[_c('div',{staticClass:"detailBoxGrey"},[_c('ClockTimeTwoOutline')],1),_vm._v(" ± 1.5 uur ")]),_c('div',{staticClass:"detailBoxItem"},[_c('div',{staticClass:"detailBoxGrey"},[_c('CashMultiple')],1),_vm._v(" €10,75,- per kind (minimaal 5 kinderen) ")]),_c('div',{staticClass:"detailBoxItem"},[_c('div',{staticClass:"detailBoxGrey"},[_c('FormatListBulleted')],1),_vm._v(" "),_c('div',{staticClass:"detailBoxTitle"},[_vm._v("Hierbij zit inbegrepen")]),_c('br'),_vm._v("   + Schort "),_c('br'),_vm._v("   + Gebruik van textielstiften en materialen "),_c('br'),_vm._v("   + Limonade met iets lekkers "),_c('br'),_vm._v("   + Grabbelen ")]),_c('div',{staticClass:"img"},[_c('vue-picture-swipe',{attrs:{"options":{shareEl: false},"items":[
        {src: require('@/assets/kinderfeestjes/_54A2290.jpg'),thumbnail: require('@/assets/kinderfeestjes/_54A2290_t.jpg'), w: 3543,h: 2362} ]}})],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"banner"},[_c('div',{staticClass:"bannercontent"},[_c('div',{staticClass:"bannertitle"},[_vm._v("Kinderfeestjes")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pcontent"},[_c('div',{staticClass:"detailBoxHead"},[_c('b',[_vm._v("Vier je creatieve kinderfeestje bij de Stipbox. "),_c('br'),_vm._v("Dat is pas feest!")])]),_vm._v(" Voordat je samen met je vriendjes en vriendinnetjes de leukste dingen gaat maken, wordt je als jarige eerst in het zonnetje gezet. De rode loper wordt voor je uitgelegd en je mag plaatsnemen op de grote verjaardagsstoel. Als we allemaal moe zijn van het zingen voor jou is het tijd voor limonade en wat lekkers. Daarna … knutselen maar! "),_c('br'),_vm._v("Oh en niet vergeten, voordat je naar huis gaat, allemaal grabbelen in de grote grabbelton. "),_c('br')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"detailBoxHead"},[_c('b',[_vm._v("Kinderfeestje")]),_vm._v(" Letter op mok")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"detailBoxHead"},[_c('b',[_vm._v("Kinderfeestje")]),_vm._v(" Tekening op bord")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"detailBoxHead"},[_c('b',[_vm._v("Kinderfeestje")]),_vm._v(" Ontwerp je eigen schort")])}]

export { render, staticRenderFns }