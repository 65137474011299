<template>
    <div>
        <div class="banner">
            <div class="bannercontent">
                <div class="logo"></div>
                <div class="bannertitle">Welkom bij de Stipbox!</div>
                <div class="bannerdesc">Van harte welkom op de website van de Stipbox!
                    <br> Ben je op zoek naar een leuke workshop of origineel kinderfeestje?
                    <br>Dan ben je hier aan het juiste adres.
                </div>
                <!-- <button class="button">Meer Info</button> -->
            </div>
        </div>

        <div class="hcontent">
            <div class="leftBox">
                <div class="workshopsLogo"></div>
                <div class="workshopsLogoS"></div>
                <div class="boxtitle">Workshops</div>
                Stippen op keramiek<br>
                <br>
                <router-link to="/workshops"><button class="button">Lees meer</button></router-link>
            </div>
            <div class="rightBox">
                <div class="kinderfeestLogo"></div>
                <div class="kinderfeestLogoS"></div>
                <div class="boxtitle">Kinderfeestjes</div>
                Vier je creatieve kinderfeestje bij de Stipbox.
                <br>Dat is past feest!
                <br>
                <router-link to="/kinderfeestjes"><button class="button">Lees meer</button></router-link>
            </div>
        </div>

        </div>
</template>

<script>
export default {
  name: 'app',
  components: {

  }
}
</script>

<style>
    .hcontent{
        max-width: 1024px;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        display: inline-block;
        min-height: calc(80vh - 40px);
    }
    .button{
        margin-top: 10px;
    }
    .kinderfeestLogo{
        display: inline-block;
        background-image: url(~@/assets/homeFeest1.jpg);
        border-radius: 50%;
        background-position: top;
        background-size: 180%;
        width: 200px;
        height: 200px;
        margin-bottom: 10px;
    }
    .kinderfeestLogoS{
        display: inline-block;
        background-image: url(~@/assets/homeFeest2.jpg);
        border-radius: 50%;
        background-position: center;
        background-size: 170%;
        width: 100px;
        height: 100px;
        margin-bottom: 10px;
        margin-left: 5px;
    }
   .workshopsLogo{
        display: inline-block;
        background-image: url(~@/assets/homeWorkshop1.jpg);
        border-radius: 50%;
        background-position-x: 0px;
        background-size: 120%;
        width: 200px;
        height: 200px;
        margin-bottom: 10px;
    }
    .workshopsLogoS{
        display: inline-block;
        background-image: url(~@/assets/homeWorkshop2.jpg);
        border-radius: 50%;
        background-position-y: -40px;
        background-position-x: center;
        background-size: 120%;
        width: 100px;
        height: 100px;
        margin-bottom: 10px;
        margin-left: 5px;
    }
    .boxtitle{
        font-size: 22px;
        font-weight: bold;
    }
    .leftBox{
        /* background-color:blue; */
        float: left;
        width: 50%;
        margin-top: 25px;
    }
    .rightBox{
        /* background-color: red; */
        float: left;
        width: 50%;
        margin-top: 25px;
    }
    .banner{
        background-image: url(~@/assets/banner.jpg);
        background-position: top;
        background-color: #d9d9d9;
        background-size: 100%;
        background-repeat: no-repeat;
        width: 100%;
        height: 400px;
        display: flex;
        justify-content: center;
    }
    .bannercontent{
        max-width: 1024px;
        width: 100%;
        align-self: center;

    }

    @media only screen and (max-width: 1024px){
        .leftBox{
        /* background-color:blue; */
        float: left;
        width: 100%;
        margin-top: 25px;
    }
    .rightBox{
        /* background-color: red; */
        float: left;
        width: 100%;
        margin-top: 25px;
    }
    .banner{
        background-size: 220%;
    }
    }
</style>
