<template>
    <div>
        <div class="banner">
            <div class="bannercontent">
                <div class="bannertitle">Contact</div>
            </div>
        </div>
        <div class="content">
        <div class="pcontent">
            <div class="headshot"></div>
                   <div class="detailBoxHead">Trinette Esselbrugge</div><br><br>
            <div style="background-color: red; color:white; font-weight: bold; padding: 20px;">Wegens privé omstandigheden tijdelijk gesloten</div><br>
            <table>
                <tr>
                    <td>Email</td>
                    <td><a href="mailto:info@destipbox.nl">info@destipbox.nl</a></td>
                </tr>
                <tr>
                    <td>Telefoon</td>
                    <td><a href="tel:+31640264785">0640264785</a></td>
                </tr>
                <br>
                <tr>
                    <td>Adres</td>
                    <a href="https://www.google.nl/maps/dir//Rooilaan+122,+7876+GV+Valthermond/">
                    <td>Rooilaan 122</td>
                    </a>
                </tr>
                <tr>
                    <td></td>
                    <a href="https://www.google.nl/maps/dir//Rooilaan+122,+7876+GV+Valthermond/">
                    <td>7876 GV, Valthermond</td>
                    </a>
                </tr>
                </table>
            <br>
            <div class="detailBoxHead">Kaart</div>
            <div class="map"></div>

        </div>
    
    </div>
    </div>
</template>

<script>
// import ClockTimeTwoOutline from 'vue-material-design-icons/ClockTimeTwoOutline.vue'
// import CashMultiple from 'vue-material-design-icons/CashMultiple.vue'
export default {
  name: 'Contact',
  components: {
    //   ClockTimeTwoOutline,
    //   CashMultiple

  }
}
</script>

<style scoped>

    .headshot{
        display: inline-block;
        background-image: url(~@/assets/contactp.jpg);
        border-radius: 50%;
        border-radius: 4px;
        /* background-position-x: 0px; */
        background-position: center;
        background-size: 100%;
        width: 100%;
        max-width: 500px;
        height: 400px;
        margin-bottom: 5px;
        background-repeat: no-repeat;
    }

     .pagetitle{
        font-size: 25px;
        float: left;
    }

    .pcontent{
        max-width: 1024px;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        display: inline-block;
        text-align: left;
    }

    .banner{
        background-image: url(~@/assets/contact.jpg);
        /* background-position-y: -650px; */
        background-position: center;
        background-color: #d9d9d9;
        background-size: 100%;
        background-repeat: no-repeat;
        width: 100%;
        height: 150px;
        display: flex;
        justify-content: center;
    }

    .bannercontent{
        max-width: 1024px;
        width: 100%;
        align-self: center;

    }
    .bannertitle{
        color: #ffffff;
    }

     .map{
        display: inline-block;
        background-image: url(~@/assets/kaart-mama-vierkant.png);
        /* border-radius: 50%; */
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        width: 100%;
        max-width: 500px;
        height: 400px;
        margin-bottom: 10px;
        border-radius: 5px;
    }
   
</style>
