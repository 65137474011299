<template>
    <div id="app">
        <Navigation/>
        <PageTitle/>
        <router-view/>
         <footer class="footer">
             <div class="footerContent">
                 <div class="footerLeft">
                     <div class="footerRow"><div class="footerIcon"><Email/></div><a href="mailto:info@destipbox.nl">info@destipbox.nl</a></div>
                     <div class="footerRow"><div class="footerIcon"><Phone/></div><a href="tel:+31640264785">0640264785</a></div>
                     <div class="footerRow"><div class="footerIcon"><MapMarker/></div><a href="https://www.google.nl/maps/dir//Rooilaan+122,+7876+GV+Valthermond/">Rooilaan 122 - 7876 GV, Valthermond</a></div>
                     <div class="footerRow"><div class="footerIcon"><Domain/></div>kvk: 77291522</div>
                        <!-- <div class="footerRow">&nbsp; &nbsp; &nbsp; &nbsp; 7876 GV, Valthermond</div> -->
                 </div>
                 <div class="footerRight">
                     <div class="footerText">Volg ons ook op social media</div>
                     <a href="https://www.facebook.com/destipbox/" class="footerSocial"> <Facebook/></a>
                     <a href="https://www.instagram.com/destipbox/" class="footerSocial"><Instagram/> </a>
                     <a href="https://wa.me/31640264785" class="footerSocial"> <Whatsapp/> </a>
                     <div class="footerText">Fotografie <a href="http://biancaverhoef.nl">biancaverhoef.nl</a></div>
                 </div>
             </div>
         </footer>
    </div>
</template>

<script>
import Navigation from "@/components/Navigation";
import PageTitle from "@/components/PageTitle";
import Facebook from 'vue-material-design-icons/Facebook.vue'
import Instagram from 'vue-material-design-icons/Instagram.vue'
import Email from 'vue-material-design-icons/Email.vue'
import Phone from 'vue-material-design-icons/Phone.vue'
import MapMarker from 'vue-material-design-icons/MapMarker.vue'
import Domain from 'vue-material-design-icons/Domain.vue'
import Whatsapp from 'vue-material-design-icons/Whatsapp.vue'

export default {
  name: 'app',
  components: {
      Navigation,
      PageTitle,
      Facebook,
      Instagram,
      Email,
      Phone,
      MapMarker,
      Domain,
      Whatsapp
  }
}
</script>

    <style>
    /* @import url('https://fonts.googleapis.com/css2?family=Avenir&display=swap'); */
    
    .logo{
        margin-right: 15px;
        display: inline-block;
        background-image: url(~@/assets/Logo.svg);
        background-size: 200px 105px;
        width: 200px;
        height: 105px;
    }
    .button {
        color: #fafafa;
        background-color: #D22D3C;
        min-width: 80px;
        min-height: 30px;
        border: none;
        border-radius: 2px;
        -webkit-transition: background-color 70ms linear;
        -moz-transition: background-color 70ms linear;
        -o-transition: background-color 70ms linear;
        transition: background-color 70ms linear;
        position: relative;
    }

    .button:hover {
        background-color: #b72430;
        cursor: pointer;
        -webkit-transition: background-color 70ms linear;
        -moz-transition: background-color 70ms linear;
        -o-transition: background-color 70ms linear;
        transition: background-color 70ms linear;
    }
    .bannertitle{
        color: #eee;
        font-weight: bold;
        font-size: 32px;
        padding-top: 10px;
        padding-bottom: 10px;
        text-shadow: 1px 1px #000;
    }
    .bannerdesc{
        color: #eee;
        padding-top: 5px;
        padding-bottom:10px;
        text-shadow: 1px 1px #000;
    }
    #app{
        height: 100%;
    }
    a{
        color: #D22D3C;
        text-decoration: none;
    }
    body {
      background-color: #eeeeee;
      margin: 0 auto;
      text-align: center;
      font-family: 'Roboto', sans-serif;
      position: relative;
      height: 100%;
    }
    html{
        height: 100%;
    }
    .detailBox{
        background-color: #e2e2e2;
        border-radius: 5px;
        display: inline-block;
        width: 100%;
        max-width: 1024px;
    }
    .detailBoxItem{
        width: inherit;
        text-align: left;
        padding: 8px;
        border-bottom: 1px;
        border-bottom-style: solid;
        border-bottom-color: #c5c5c5;
        box-sizing:border-box;
        font-family: Avenir,Helvetica,Arial,sans-serif;
    }
    .detailBoxItem:last-child{
        border-bottom: 0px;
    }
    .detailBoxGrey{
        color: #e4e4e4;
        display: inline-block;
        background-color: #b72430;
        padding-top: 3px;
        padding-bottom: 3px;
        padding-left: 6px;
        padding-right: 6px;
        border-radius: 50%;
    }
    .detailBoxTitle{
        color: #282828;
        display: inline-block;
        margin-bottom: 3px;
        font-weight: bold;
    }
    .detailBoxHead{
        color: #282828;
        margin-bottom: 3px;
        /* font-weight: bold; */
        display: inline-block;
        width: 100%;
        max-width: 1024px;
        text-align: left;
        font-size: 20px;
    }
    @media only screen and (max-width: 1024px){
        .content{
            padding: 10px;
        }
    }

    .content{
        min-height: calc(100vh - 40px);
    }

    .footer{
        margin-top: 50px;
        height: 120px;
        background-color: #ffffff;
        border: 0px;
        border-top: 1px;
        border-style: solid;
        border-color: #dadada;
        display: flex;
        justify-content: center;
        font-size: 14px;
    }

    .footerLeft{
         flex: 1;
        /* Grow to rest of container */
        /* background: lightgreen; */
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        text-align: left;
        padding-left: 5px;

        border: 0px;
        border-right: 1px;
        border-style: solid;
        border-color: #dadada;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .footerRow{
        flex-basis: 100%;
        /* background-color: green; */
        

    }

    .footerRight{
        width: 150px;
    
        vertical-align: middle;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }

    .footerContent{
        display: flex;
        width: 100%;
        max-width: 1024px;
        /* background-color: rebeccapurple; */
        color: rgb(122, 122, 122);
    }

    .footerSocial{
        display: inline-block;
        background-color: #c4343b;
        width: 37px;
        height: 37px;
        margin-left: 5px;
        font-size: 25px;
        line-height: 37.5px;
        border-radius: 50%;
        color: rgb(240, 240, 240)
    }

    .fb{
        background-color: #1877f2;
    }

    .footerIcon{
        display: inline-block;
        background-color: #c4343b;
        width: 20px;
        height: 20px;
        margin-left: 5px;
        font-size: 14px;
        line-height: 22px;
        border-radius: 50%;
        color: rgb(240, 240, 240);
        text-align: center;
        margin-right: 10px;

    }
    .footerSocial:first-child{
        margin-left: 0px;
    }
    .footerText{
        flex-basis: 100%;
        color: rgb(122, 122, 122);
        font-size: 14px;
    }
</style>
