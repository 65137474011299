<template>
    <div class="topBarItem" :class="{ 'right' : right}">
        <div class="title">{{title}}</div>
        <chevron-down-icon v-if="icon" class="icon"/>
    </div>
</template>

<script>
    import ChevronDownIcon from 'vue-material-design-icons/ChevronDown.vue'
    export default {
        name: "NavigationItem",
        components: {
          ChevronDownIcon
        },
        props: {
            title: String,
            right: Boolean,
            icon: Boolean
        }
    }
</script>

<style scoped>
    .topBarItem{
        height: 100%;
        line-height: 58px;
        display:inline-block;
        vertical-align:top;
        text-align: center;
        margin-right: 15px;
        color: #707070;
        cursor: pointer;
    }
    .topBarItem:hover{
        color: #D22D3C;
        transition:.2s;
    }
    .title{
        display: inline-block;
    }
    .icon{
        margin-left: 5px;
    }
    .right{
        float: right;
    }
    @media only screen and (max-width: 600px){
        .right{
            float: inherit;
        }
    }
</style>