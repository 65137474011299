import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Workshop from '../views/Workshops.vue'
import Kinderfeestjes from '../views/Kinderfeestjes.vue'
import Contact from '../views/Contact.vue'
import Gallery from '../views/Gallery.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/workshops',
    name: 'Workshop',
    component: Workshop
  },
  {
    path: '/kinderfeestjes',
    name: 'Kinderfeestjes',
    component: Kinderfeestjes
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/gallery',
    name: 'Gallery',
    component: Gallery
  },
 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView({ behavior: 'auto' });
  }
})

export default router