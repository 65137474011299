<template>
    <div>
    <div style="background-color: red; color:white; font-weight: bold; padding: 20px; font-size:20px;">Wegens privé omstandigheden tijdelijk gesloten</div>
    <div class="topBar">
        <div class="topBarContent">
            <router-link to="/"><div class="logo"></div></router-link>

            <router-link to="/"><NavigationItem title="Home"/></router-link>
            <router-link to="/gallery"><NavigationItem title="Galerij"/></router-link>
            <router-link to="/workshops"><NavigationItem title="Workshops"/></router-link>
            <router-link to="/kinderfeestjes"><NavigationItem title="Kinderfeestjes"/></router-link>

            <router-link to="/contact"><NavigationItem title="Contact" right="true"/></router-link>

        </div>

    </div>
    </div>
</template>

<script>
    import NavigationItem from "@/components/NavigationItem";
    export default {
        name: "Navigation",
        components: {NavigationItem}
    }
</script>

<style scoped>
    .topBar{
        background-color: #ffffff;
        box-shadow: 0 1px 0 #cfcfcf;
        text-align: center;
        width: 100%;
        height: 55px;
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        z-index: 100;
    }
    .topBarContent{
        text-align: left;
        display: inline-block;
        max-width: 1024px;
        width: 100%;
        height: 55px;
        overflow: auto;
        white-space: nowrap;  
        overflow-y: hidden;
    }
    .logo{
        margin-right: 15px;
        display: inline-block;
        background-image: url(~@/assets/Logo.svg);
        background-size: 70px 55px;
        width: 70px;
        height: 55px;
    }
</style>